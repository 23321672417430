<template>
  <div class="flex auto-align color-white floating">
    <h1 class="unselectable flex">{{ header }}</h1>
    <SelectBox class="inline-padding"
      name="Address:"
      content="nano_3o5oeefdnrha7x7styp1tnmefen7fnrooy4jgnfb1otws54yf7uqfuxmojoy"
    />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";

export default {
  name: "HeaderComponent",
  data: function () {
    return {
      header: "S coin Nano Rep",
    };
  },
  components: { SelectBox },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='css'>
h1 {
  font-weight: 400;
  font-size: 2rem;
}

.unselectable {
  user-select: none;
}

.floating {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0 5vw;
}

.auto-align {
  text-align: center;
  margin: auto;
}

.flex {
  display: flex;
  flex-direction: column;
  min-height: min-content;
  max-height: min-content;
  align-items: center;

}

.color-white {
  color: hsl(0, 000%, 100%);
}

.inline-padding {
  padding: 0;
}

@media screen and (min-width: 800px) {
  .flex {
    flex-direction: column;
  }

  .auto-align {
    text-align: left;
    margin: auto;
  }
}

@media screen and (min-width: 1125px) {
  .flex {
    flex-direction: row; 
  }

  .floating {
  position: absolute;
  margin: auto;
  left: 0;
  right: unset;
  padding: 0 1vw;
}

}
</style>