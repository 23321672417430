<template>
  <div class="address flex-type rounded font-scaled">
    <span class="inline-padding unselectable capitalize center font-scaled"
      >{{ name }}
    </span>
    <input
      type="text"
      class="address inline-padding center font-scaled"
      readonly="true"
      onclick="this.select()"
      v-bind:value="content"
      size="64"
    />
  </div>
</template>

<script>
export default {
  name: "SelectBox",
  props: {
    name: String,
    content: String,
  },
};
</script>

<style scoped>
.flex-type {
  flex-direction: row;
}

.font-scaled {
  font-size: 2.2vw;
}

.inline-padding {
  display: inline-block;
  margin: 0 0 0 0.5em;
  padding: 0.5em 0;
}

.unselectable {
  user-select: none;
}

.address {
  background-color: #ffffffaa;
  color: black;
  width: fit-content;
}

.address > span {
  display: none;
}

.address input {
  background-color: unset;
  outline: none;
  border: none;
  text-align: center;
  width: 36em;
  height: fit-content;
  padding: 0.25em 0.5em;
  margin: auto;
  font: inherit;
}

.rounded {
  border-radius: 0.5rem;
}

.color-white {
  color: hsl(0, 000%, 100%, 80%);
}

@media screen and (min-width: 800px) {
  .font-scaled {
    font-size: 1.1em;
  }

  .address > span {
    display: inherit;
  }
  
  .inline-padding {
    padding: 0.5em 0;
  }

  .flex-type {
    display: inline-flex;
    flex-direction: row;
    min-height: min-content;
    max-height: min-content;
    align-content: center;
  }
}
</style>