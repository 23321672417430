<template>
  <div id="main">
    <HeaderComponent />
  </div>
</template>

<script>
import HeaderComponent from "../components/Header";
export default {
  name: "HomePage",
  components: {
    HeaderComponent,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='css'>
#home {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
</style>