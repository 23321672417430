<template>
  <Home />
</template>

<script>
import Home from "./pages/Home";

export default {
  name: "App",
  created() {
    this.preload();
  },
  methods: {
    preload: () => {
      let anims = {};
      document.getAnimations().reduce((result, item) => {
        result[item.animationName] = item;
      }, anims);
      anims["color-rotate"].currentTime = Date.now() % 20000;
    },
  },
  components: {
    Home,
  },
};
</script>

<style lang='css'>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;400&display=swap");

body {
  margin: 10px;
  font: 1em "Work Sans", sans-serif;
  font-family: "Work Sans", Helvetica, sans-serif;
  background-image: url("~@/assets/scoin.png");
  background-blend-mode: normal;
  background-clip: content-box;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ff8888;
  background-size: contain;
  animation: color-rotate 20s 0s infinite linear;
}

@keyframes color-rotate {
  0% {
    background-color: hsl(0, 100%, 77%);
  }
  25% {
    background-color: hsl(90, 100%, 77%);
  }
  50% {
    background-color: hsl(180, 100%, 77%);
  }
  75% {
    background-color: hsl(270, 100%, 77%);
  }
  100% {
    background-color: hsl(360, 100%, 77%);
  }
}
</style>